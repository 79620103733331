<template>
  <b-card>
    <b-card-title>Photos</b-card-title>
    <b-card-sub-title>
      Good quality photos allow guests to get a better idea about the property.
      We suggest you to upload clear and great photos in relevant categories that represent
      all your property has to offer. These photos will be displayed on your property's page.
    </b-card-sub-title>
    <b-card-body>
      <div class="pb-2">
        <p>Choose feature image and upload.</p>
        <b-form-file
          ref="feature-images-uploader"
          v-model="featured_picture"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept="image/jpeg, image/png, image/jpg"
          class="w-50"
          @input="showImageUploader(featured_picture, 'feature')"
        />
        <div class="mt-2">
          <div
            v-if="previewFeaturedPicture"
            class="image-list d-flex flex-wrap"
          >
            <div
              class="image-wrapper"
            >
              <b-img
                :src="previewFeaturedPicture"
                thumbnail
                fluid
              />
            </div>
          </div>
        </div>
      </div>
      <!--room image uploader section-->
      <div class="border-top pt-2">
        <p>Choose general photos and upload.</p>
        <b-form-file
          ref="general-images-uploader"
          v-model="pictures"
          placeholder="Choose files or drop it here..."
          drop-placeholder="Drop file here..."
          accept="image/jpeg, image/png, image/jpg"
          multiple
          :disabled="isBtnLoading"
          class="w-50"
          @input="showImageUploader(pictures, 'general')"
        />
        <div class="image-placeholder">
          <div
            v-if="previewPictures"
            class="room-images"
          >
            <div class="image-list d-flex flex-wrap">
              <div
                v-for="(picture, p) in previewPictures"
                :key="p"
                class="image-wrapper"
              >
                <feather-icon
                  icon="XIcon"
                  class="remove-icon"
                  @click="removePicture(picture, p)"
                />
                <b-img
                  :src="picture.img"
                  thumbnail
                  fluid
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
    <div
      v-if="tourId"
      class="text-right p-2 border-top"
    >
      <b-button
        variant="outline-primary"
        :to="`/property-register/step-5/${tourId}`"
      >
        Itinerary
        <feather-icon
          icon="ArrowRightIcon"
          size="12"
        />
      </b-button>
    </div>
    <ImageViewer
      :category-type="categoryType"
      :is-loading="isBtnLoading"
      :images-to-show="imagesToEdit"
      @uploadImages="uploadImages"
      @clearInput="clearInput"
    />
  </b-card>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import tour from '@/mixins/tour'
import ImageViewer from '@/components/common/ImageViewer.vue'

export default {
  name: 'Photos',
  components: {
    ImageViewer,
  },
  mixins: [tour, helper],
  data() {
    return {
      isBtnLoading: false,
      previewPictures: [],
      pictures: [],
      previewFeaturedPicture: null,
      featured_picture: null,
      tourId: null,
      categoryType: '',
      imageIndex: '',
      imagesToEdit: [],
      isImageUploaded: false,
      isUploading: false,
    }
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.tourId = this.$route.params.id
      this.getTourPhotos()
    }
  },
  methods: {
    getTourPhotos() {
      this.getTourData(this.tourId).then(response => {
        if (response.data.status) {
          this.previewFeaturedPicture = response.data.result.featured_picture
          this.previewPictures = response.data.result.pictures
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          if (response.data.error.code === 400) {
            this.$router.push('/')
          }
        }
      }).catch()
    },
    showImageUploader(images, type) {
      if (type === 'general' && images.length > 0) {
        this.imagesToEdit = images
        this.categoryType = type
      } else if (images && type === 'feature') {
        this.imagesToEdit.push(images)
        this.categoryType = type
      }
      this.isUploading = true
      if (this.imagesToEdit.length > 0 && this.isUploading) {
        this.$root.$emit('bv::toggle::collapse', 'image-viewer')
      }
    },
    uploadImages(images, type) {
      this.isBtnLoading = true
      const url = `/tour/admin_services/tour/${this.tourId}/featured_picture/`
      if (type === 'feature') {
        const formData = new FormData()
        formData.append('featured_picture', images[0])
        this.callPhotosApi(url, formData)
      } else {
        this.pictures = images
        this.uploadTourPictures()
      }
    },
    callPhotosApi(url, data) {
      api.updateData(url, true, data).then(response => {
        if (response.data.status) {
          this.previewFeaturedPicture = response.data.result.featured_picture
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Data updated successfully')
          if (this.isUploading) {
            this.$root.$emit('bv::toggle::collapse', 'image-viewer')
          }
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
    async uploadTourPictures() {
      const file = this.pictures
      const photos = this.previewPictures
      let maxIndex = photos.length > 0 ? photos.sort((a, b) => (b.p_index - a.p_index))[0].p_index + 1 : 1
      for (let i = 0; i < file.length; i += 1) {
        const formData = new FormData()
        formData.append('img', file[i])
        formData.append('p_index', maxIndex)
        maxIndex += 1
        // eslint-disable-next-line no-await-in-loop
        const image = await api.postData('tour/admin_services/tour_picture/', true, formData)
        if (image.data.status) {
          this.previewPictures.push(image.data.result)
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', JSON.stringify(image.data.error.message))
        }
      }
      this.sendTourPictures()
    },
    sendTourPictures() {
      const payload = { pictures: this.previewPictures.map(p => p.id) }
      const url = `/tour/admin_services/tour/${this.tourId}/picture_update/`
      this.callPhotosApi(url, payload)
    },
    removePicture(image, index) {
      const formData = {
        id: image.id,
        p_index: image.p_index,
      }
      this.isUploading = false
      api.updateData(`tour/admin_services/tour_picture/${image.id}/?delete_old=true`, true, formData).then(response => {
        if (response.data.status) {
          this.previewPictures.splice(index, 1)
          this.sendTourPictures()
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      })
    },
    clearInput() {
      this.featured_picture = null
      this.pictures = []
      this.imagesToEdit = []
    },
  },
}
</script>

<style lang="scss" scoped>
h4.card-title {
  font-weight: bold;
}
h6.card-subtitle {
  line-height: 1.5rem;
}
.image-placeholder {
  border: 1px dashed #d7d6ee;
  border-radius: 5px;
  margin: 20px 0 0;
  padding: 10px;
  height: 200px;
  overflow-y: auto;
}
.image-wrapper {
  width: 150px;
  height: 150px;
  margin: 0 10px 10px 0;
  position: relative;
  .remove-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #eceaff;
    padding: 5px;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
