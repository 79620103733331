<template>
  <b-sidebar
    id="image-viewer"
    ref="image-viewer"
    width="100%"
    title="Image Viewer"
    bg-variant="white"
    right
    shadow
    backdrop
    no-header
    @shown="fetchSelectedImages"
    @hidden="clearList"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5>Image Viewer</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div class="d-block text-center image-editor p-1">
        <h4 class="mb-1 text-primary text-bold">
          Upload images of {{ categoryType }}
        </h4>
        <div class="images">
          <div
            class="image d-flex"
          >
            <div
              v-for="(image, i) in imageList"
              :key="i"
              class="image-thumbs"
            >
              <feather-icon
                icon="XIcon"
                class="remove-icon"
                @click="deleteImage(i)"
              />
              <b-img
                :src="image"
                thumbnail
                fluid
              />
              <div class="my-1">
                <b-button
                  size="sm"
                  variant="primary"
                  class="w-100"
                  @click="showImageEditor(image, i)"
                >
                  Edit</b-button>
              </div>
            </div>
            <div class="image-thumbs image-upload-placeholder">

              <label
                for="image-upload-input"
              >
                <img src="@/assets/images/icons/icon-photo-upload.svg">
                <span>Upload your images</span>
              </label>
              <b-form-file
                id="image-upload-input"
                ref="image-upload-input"
                v-model="images"
                accept="image/jpeg, image/png, image/jpg"
                multiple
                drop-placeholder="Drop file here..."
                @input="getImageFiles()"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end align-items-center content-sidebar-header px-2 py-1">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading || fileList.length === 0"
          @click="uploadImages()"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          Upload Images
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import helper from '@/mixins/helper'

export default {
  name: 'ImageViewer',
  mixins: [helper],
  props: {
    categoryId: {
      type: [Number, String],
    },
    categoryType: {
      type: String, // 'room', 'property'
    },
    isLoading: {
      type: Boolean,
    },
    categoryName: {
      type: String,
      default: '',
    },
    imagesToShow: {
      type: Array,
    },
  },
  data() {
    return {
      imageList: [],
      images: [],
      imageEditIndex: '',
      fileList: [],
    }
  },
  methods: {
    showImageEditor(url, index) {
      const config = {
        tools: ['adjust', 'rotate', 'crop', 'resize', 'effects', 'filters', 'watermark'],
        replaceCloseWithBackButton: true,
        finishButtonLabel: 'Save',
      }
      const onBeforeComplete = props => {
        const { canvas } = props
        const fileName = this.fileList[index].name
        canvas.toBlob(blob => {
          const file = new File([blob], fileName)
          const image = URL.createObjectURL(file)
          this.fileList.splice(index, 1, file)
          this.imageList.splice(index, 1, image)
        })
        return false
      }
      const ImageEditor = new window.FilerobotImageEditor(config, {
        onBeforeComplete,
      })
      ImageEditor.open(url)
    },
    uploadImages() {
      this.$emit('uploadImages', this.fileList, this.categoryType)
    },
    getImageFiles() {
      this.images.forEach(image => {
        this.fileList.push(image)
        this.imageList.push(URL.createObjectURL(image))
      })
      this.$refs['image-upload-input'].reset()
    },
    fetchSelectedImages() {
      if (this.imagesToShow.length > 0) {
        this.imagesToShow.forEach(image => {
          this.fileList.push(image)
          this.imageList.push(URL.createObjectURL(image))
        })
      }
    },
    clearList() {
      this.fileList = []
      this.imageList = []
      this.$emit('clearInput')
    },
    deleteImage(index) {
      this.imageList.splice(index, 1)
      this.fileList.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modal {
  padding-left: 0 !important;
  .modal-content {
    height: 95vh !important;
  }
  &.show {
    .modal-dialog {
      margin-top: 0 !important;
    }
  }
}
::v-deep .b-form-file {
  display: none;
}
.images {
  .image {
    .image-thumbs {
      position: relative;
      width: 200px;
      height: 200px;
      margin-right: 10px;
      .remove-icon {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: #eceaff;
        padding: 5px;
        border-radius: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .image-upload-placeholder {
      border: 1px dashed #b1b1b1;
      border-radius: 10px;
      label {
        margin-bottom: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        padding: 10px;
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
</style>
