import api from '@/libs/api'

export default {
  methods: {
    getTourData(id) {
      return api.getData(`tour/admin_services/tour/${id}/`, true)
    },
    getAreaSuggestionFromBK(query) {
      return api.getData(`location/map/autocomplete/?search=${query}`, true)
    },
    getReverseGeocoding(lat, long) {
      return api.getData(`location/map/geo_code/?latitude=${lat}&longitude=${long}`)
    },
    changeImageOrder(event, pictures) {
      const { oldIndex, newIndex } = event.moved
      let start
      let end
      if (newIndex > oldIndex) {
        start = 0
        end = newIndex
      } else {
        start = newIndex
        end = pictures.length - 1
      }
      for (let index = start; index <= end; index += 1) {
        const image = pictures[index]
        api.updateData(`tour/admin_services/tour_picture/${image.id}/`, true, { id: image.id, p_index: index + 1 })
      }
    },
  },
}
